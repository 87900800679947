import { Button, Form, Input, Modal, Space, Spin, Switch, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DELETERestaurant, ListRestauantByADmin, reportedRestaurantActiveInactive, updateFrnachiseToggle } from '../../Redux/RestaurantManagementSlice';
import { DeleteIcon, EditIcon, ViewIcon } from '../../svg';
import { debounce } from '../../utils';
import { usePagination } from '../../utils/pagination';

const RestaurantList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { currentPage, onChangePage, onChangePageSize, pageSize, searchTerm, onSearchPagination, clearSearch } = usePagination();
    const { restaurantList, recordsTotal } = useSelector(state => state.restaurant) || {}

    const [modalDeleteId, setModalDeleteId] = useState();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState(searchTerm);


    const [args, setArgs] = useState(
        {
            "search": searchTerm,
            "start": (currentPage - 1) * pageSize,
            "length": pageSize,
        }
    )

    // Debounced fetch data function
    const fetchData = useCallback(debounce((args) => {
        setLoading(true);
        dispatch(ListRestauantByADmin(args))
            .then(() => setLoading(false))
            .catch((error) => {
                setLoading(false);
                toast.error(error.message);
            });
    }, 1000), [dispatch]);

    useEffect(() => {
        fetchData(args);
    }, [args, fetchData]);

    useEffect(() => {
        setArgs({
            search: searchTerm,
            start: (currentPage - 1) * pageSize,
            length: pageSize,
        });
    }, [searchTerm, currentPage, pageSize]);


    const onChange = (e, row) => {
        setLoading(true)
        const statusRequest = {
            restaurantId: row?._id,
            isFranchise: e
        }
        dispatch(updateFrnachiseToggle(statusRequest))
            .then(response => {
                if (response) {
                    setLoading(false)
                }
                setLoading(true)
                dispatch(ListRestauantByADmin(args)).then((response) => {
                    if (response) {
                        setLoading(false)
                    }
                })
                toast.success(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch(err => {
                setLoading(false)
                toast.error(err)
            })

    };
    const showDeleteModal = (record) => {
        setDeleteModalOpen(true);
        setModalDeleteId(record?._id)
    };


    const onChangeVenueActiveInactive = (row) => {
        const statusRequest = {
            id: row?._id
        }
        setLoading(true)
        dispatch(reportedRestaurantActiveInactive(statusRequest))
            .then(response => {
                setLoading(false)
                dispatch(ListRestauantByADmin(args))
                toast.success(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch(err => {
                toast.error(err.message)
                setLoading(false)
            })

    };
    const columns = [
        {
            title: "Owner Name",
            dataIndex: "venueOwnerName",
            key: "venueOwnerName",
            className: 'ant-th-venue ant-regular',
            render: (row, record) => (
                <div className='location'>
                    {record?.venueOwnerName ? record?.venueOwnerName : "N/A"}
                </div>
            ),
        },
        {
            title: "Restaurant Name",
            dataIndex: "name",
            key: "name",
            className: 'ant-th-venue ant-regular',
            sorter: (a, b) => a?.name - b?.name,
            render: (row, record) => (
                <div className='location'>
                    {record?.name}
                </div>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            className: 'ant-th-venue ant-regular',
            sorter: (a, b) => a?.email - b?.email,
            render: (row, record) => (
                <div className='location'>
                    {record?.email ? record?.email : "-"}
                </div>
            ),
        },
        {
            title: "Location",
            dataIndex: "address",
            key: "address",
            className: 'ant-th-venue ant-regular',
            sorter: (a, b) => a?.address - b?.address,
            render: (row, record) => (
                <div className='location'>
                    {record?.address ? record?.address : "-"}
                </div>
            ),
        },

        {
            title: "Goats",
            dataIndex: "totalGoats",
            key: "totalGoats",
            className: 'ant-th-venue',
            render: (row, record) => (
                <div className='number'>
                    {record?.totalGoats}
                </div>
            ),
        },
        {
            title: "Franchise Name",
            dataIndex: "restaurantFranchise",
            key: "restaurantFranchise",
            className: "ant-th-venue",
            render: (row, record) => (
                <div>{record?.restaurantFranchise?.name ? record?.restaurantFranchise?.name : "-"}</div>
            ),
        },
        {
            title: "Is this venue a franchise?",
            className: 'ant-th-venue',
            render: (cell, row) => <Space size={10}>
                <Switch onChange={(e) => { onChange(e, row) }} checked={row?.isRestaurantFranchise === true ? true : false} />

            </Space>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            className: "action ant-th-venue",
            render: (cell, row) => <Space size={10}>
                <Switch onChange={() => onChangeVenueActiveInactive(row)} checked={row?.isActive === true ? true : false} />

                <Button htmlType="submit" className='view-btn' onClick={() => navigate('/viewrestaurant', {
                    state: {
                        record: row
                    }
                })}><ViewIcon /></Button>
                <Button htmlType="submit" className='edit-btn' onClick={() =>
                    navigate('/editrestaurant', {
                        state: {
                            record: row
                        }

                    })}><EditIcon /></Button>
                <Button htmlType="submit"
                    className='delete-btn'
                    onClick={() => showDeleteModal(row)} >
                    <DeleteIcon /></Button>
            </Space>
        },
    ];

    const onTableChange = (newState, _, sorter) => {
        const { current, pageSize } = newState;
        onChangePage(current);
        onChangePageSize(pageSize);
        setArgs({
            ...args,
            start: Number(current - 1) * pageSize,
            length: pageSize,
            sort: sorter.field,
            dir: sorter.order
        })
    }

    const handleDeleteCancel = () => {
        setDeleteModalOpen(false);
    };
    const handleDeleteOk = (id) => {
        let data = {
            id: id,
        };
        setDeleteModalOpen(false);
        dispatch(DELETERestaurant(data))
            .then(response => {
                toast.success(response.message)
                if (response?.status === 200) {
                    setLoading(true)
                }
                dispatch(ListRestauantByADmin(args)).then((response) => {
                    if (response) {
                        setLoading(false)
                    }
                })
                    .catch((error) => toast.error(error.message))
            })
    };

    const debouncedOnSearch = useCallback(debounce((value) => {
        if (value === "") {
            clearSearch();
        } else {
            onSearchPagination(value);
        }
    }, 2000), []);

    const handleSearchChange = (e) => {
        setInputValue(e.target.value);
        debouncedOnSearch(e.target.value);
    };

    return (
        <>
            <div className='shadow-paper'>
                <div className='search-group d-flex align-items-center justify-content-between'>
                    <Form.Item>
                        <Input type='text' placeholder="Search" onChange={handleSearchChange} value={inputValue} />
                    </Form.Item>
                    <Button type="primary" onClick={() => navigate('/addrestaurant')}> Add</Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={restaurantList ? restaurantList : []}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        total: recordsTotal || 0,
                        pageSize: pageSize,
                        current: currentPage
                    }}
                    onChange={onTableChange}
                    scroll={{ x: 1200 }}
                    loading={{ indicator: <div><Spin size='large' /></div>, spinning: loading }}
                />


            </div>
            <Modal
                title="Delete Restaurant"
                open={deleteModalOpen}
                className='common-modal'
                width={448}
                centered
                onOk={() => handleDeleteOk(modalDeleteId)}
                onCancel={handleDeleteCancel}
                footer={[
                    <Space size={10} className='justify-content-end'>
                        <Button type="seconadary" onClick={handleDeleteCancel} >No</Button>
                        <Button type="primary" onClick={() => handleDeleteOk(modalDeleteId)}>Yes</Button>
                    </Space>
                ]}
            >
                <Form>
                    <p>Are you sure you want to delete? </p>
                </Form>
            </Modal>
        </>
    );
}

export default RestaurantList